import { ElementRef, Injectable } from '@angular/core';
import { IgxOverlayOutletDirective, IgxOverlayService, OverlaySettings } from '@infragistics/igniteui-angular';
import { OverlayInfo } from '@infragistics/igniteui-angular/lib/services/overlay/utilities';
import { Interface } from 'app/helpers/interface';

@Injectable({ providedIn: 'root' })
export class IgxGlobalOverlayService {

    private overlayId: string;
    private overlayOutlet: IgxOverlayOutletDirective;

    constructor(private igxOverlayService: IgxOverlayService) { }

    public get overlay() {
        return {
            info: this.overlayId == null ? undefined : this.igxOverlayService.getOverlayById(this.overlayId),
            outlet: this.overlayOutlet
        }
    }

    public register(overlayOutlet: IgxOverlayOutletDirective): void {
        this.detach();
        this.overlayOutlet = overlayOutlet;
    }

    public attach(element: ElementRef<any>, settings: Omit<OverlaySettings, 'outlet'> = {}): void {
        this.detach();
        this.overlayId = this.igxOverlayService.attach(element, {
            ...settings,
            outlet: this.overlayOutlet
        });
    }

    public detach(): void {
        if (this.overlayId != null) {
            this.igxOverlayService.detach(this.overlayId);
        }
    }
}

export class MockIgxGlobalOverlayService implements Interface<IgxGlobalOverlayService> {
    public register(overlayOutlet: IgxOverlayOutletDirective): void {
        throw new Error('Method not implemented.');
    }
    public attach(element: ElementRef<any>, settings?: Omit<OverlaySettings, 'outlet'>): void {
        throw new Error('Method not implemented.');
    }
    public detach(): void {
        throw new Error('Method not implemented.');
    }
    get overlay() {
        return {
            info: {} as OverlayInfo,
            outlet: { element: {}, nativeElement: {} } as IgxOverlayOutletDirective
        }
    }
}